import ControlledTabs from "@components-core/ControlledTabs";
import ProductFavorite from "@components-core/Favorite";
import ImageCarousel from "@components-core/ImageCarousel";
import ImagePreview from "@components-core/ImagePreview";
import ImageSlider from "@components-core/ImageSlider";
import { connectHOCs } from "@components-utils";
import RibbonAwareComponent from "@components/Ribbon/RibbonAwareComponent";
import {
  RIBBON_SELECTION_ARTICLE_ONLY,
  RIBBON_SELECTION_ARTICLE_PAGE_ONLY
} from "@constants";
import HelmetProps from "@prop-types/HelmetProps";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MediaProps from "@prop-types/MediaProps";
import ProductProps from "@prop-types/ProductProps";
import ProductReviewWidgetProps from "@prop-types/ProductReviewWidgetProps";
import ProductSpecsProps from "@prop-types/ProductSpecsProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { ProductBS, ProductTitleBS } from "@style-variables";
import { actualDeviceBreakpoint, mediaBreakpoint } from "@utils/breakpoints";
import { getSEOScoreLevel, toHelmetJSX } from "@utils/functions";
import { SUPPORTED_VIDEO_EXT } from "@utils/image";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import MediaQuery from "react-responsive";
import { imgSVG } from "../../sites/_default-img";
import EnergyClass from "../EnergyClass/EnergyClass";
import LayoutItem from "../Layout/Item";
import ProductButtons from "./Buttons";
import ProductPrice from "./Price";
import ProductStockStatus from "./StockStatus";
import ProductTitle from "./Title";
import ProductVariant from "./Variant";

class Product extends RibbonAwareComponent {
  constructor(props) {
    super(props);

    this.carouselRef = React.createRef(); // pass the carousel instance to the slider

    this.tabbedCardRef = React.createRef(); // scroll to this dummy DIV element on readReviewClick

    this.handleImageChange = this.handleImageChange.bind(this);

    this.review = { ...props.review };

    this.setActiveTab = this.setActiveTab.bind(this);

    this.review.events.onReadReview = e =>
      this.setActiveTabThenScrollIntoView(this.review.setup.activeTabIndex);

    this.state = {
      ...this.state,
      preview: false,
      activeTabIndex: null,
      activeCarouselIndex: this.getCarouselIndexByHash(0),
      activePreviwIndex: 0,
      review: null
    };

    //if (this.props.related && this.props.related.length) {
    this.relatedProductSlider = null;

    this.availability = this.props.availability;

    //}
  }

  getImageSliderSize() {
    switch (actualDeviceBreakpoint()) {
      case "mobilePortrait":
        return 80;
      case "mobileLandscape":
      case "tabletPortrait":
        return 76;
      case "tabletLandscape":
      case "desktop":
      default:
        return 110;
    }
  }

  getImageSliderColspan() {
    switch (actualDeviceBreakpoint()) {
      case "mobilePortrait":
        return 3;
      case "mobileLandscape":
      case "tabletPortrait":
      case "tabletLandscape":
        return 4;
      case "desktop":
      default:
        return 5;
    }
  }

  // getImageCarouselSize() {
  //   switch (actualDeviceBreakpoint()) {
  //     case mediaBreakpoint.mobilePortrait:
  //       return 214;
  //     case mediaBreakpoint.mobileLandscape:
  //       return 214;
  //     case mediaBreakpoint.tabletPortrait:
  //       return 414;
  //     case mediaBreakpoint.tabletLandscape:
  //       return 414;
  //     case mediaBreakpoint.desktop:
  //     default:
  //       return 614;
  //   }
  // }

  getCarouselIndexByHash(defaultIndex) {
    const imageIndexByHash = this.props.images.findIndex(
      image =>
        "#" + (image.img.src || "").replace(/\//g, "-") === window.location.hash
    );

    if (-1 !== imageIndexByHash) {
      return imageIndexByHash;
    }

    return defaultIndex;
  }

  getCarouselIndexByEnergyLabel(defaultIndex) {
    const imageIndexByLabel = this.props.images.findIndex(
      image => image.img.src && image.isEnergyLabel
    );

    if (-1 !== imageIndexByLabel) {
      return imageIndexByLabel;
    }

    return defaultIndex;
  }

  fixRibbonStyle(index, callback) {
    const carouselRef = this.carouselRef.current.itemRefs[index];

    const interval = setInterval(() => {
      const img = carouselRef.current
        ? carouselRef.current.querySelector("img")
        : null;

      if (!img) {
        clearInterval(interval);
        callback();
      } else if (img.complete && img.naturalHeight !== 0) {
        clearInterval(interval);

        return callback(
          this.state.ribbons
            .filter(ribbon =>
              ribbon.items.some(
                item =>
                  RIBBON_SELECTION_ARTICLE_PAGE_ONLY === item.itemSelection ||
                  RIBBON_SELECTION_ARTICLE_ONLY === item.itemSelection
              )
            )
            .map(ribbon => {
              return {
                ...ribbon,
                items: ribbon.items.map(item => {
                  const props = {};

                  if (["corner"].includes(item.type)) {
                    props.imgOffsetLeft = img.offsetLeft;
                    props.imgHeight = img.height;
                    props.imgWidth = img.width;
                  } else if (this.props.ribbonWrapsAround) {
                    if (
                      ["topLeft", "bottomLeft", "left"].includes(item.position)
                    ) {
                      props.marginLeft = img.offsetLeft + "px";
                      props.marginRight = item.marginRight;
                    } else if (
                      [
                        "topRight",
                        "bottomRight",
                        "right",
                        "top",
                        "bottom"
                      ].includes(item.position)
                    ) {
                      props.marginRight = img.offsetLeft + "px";
                      props.marginLeft = item.marginLeft + "px";
                    }

                    if (
                      ["bottomLeft", "bottomRight", "bottom"].includes(
                        item.position
                      )
                    ) {
                      props.top = "calc(100% - 2*var(--font-size))";
                    }
                  }

                  return { ...item, ...props };
                })
              };
            })
        );
      }
    }, 5);
  }

  componentDidMount() {
    super.componentDidMount();

    const tabIndexByHash = this.props.tabs.items.findIndex(
      item => "#" + item.id === window.location.hash
    );

    if (-1 !== tabIndexByHash) {
      this.setActiveTabThenScrollIntoView(tabIndexByHash);
    }

    this.fixRibbonStyle(this.state.activeCarouselIndex, ribbons => {
      if (ribbons) {
        this.setState({ ribbons });
      }
    });
  }

  setActiveTabThenScrollIntoView(index) {
    this.setActiveTab(index, () => {
      // scrollWithFallback({
      //   left: 0,
      //   top:
      //     this.tabbedCardRef.current.offsetTop +
      //     this.tabbedCardRef.current.clientHeight -
      //     document.querySelector(".page-header").clientHeight,
      //   behavior: "smooth"
      // })
    });
  }

  setActiveTab(activeTabIndex, callback = null) {
    this.setState(
      {
        ...this.state,
        activeTabIndex:
          this.state.activeTabIndex === null ||
          activeTabIndex === this.state.activeTabIndex
            ? -1
            : activeTabIndex
      },
      callback
    );
  }

  getTabbedCard(activeTabIndex = null) {
    if (false === !!this.props.tabs || !this.props.tabs.items.length) {
      return null;
    }

    const tabs = this.props.tabs.items.map((item, index) => {
      if ("function" === typeof item.content) {
        const tab = item.content(this.props);

        const containerClassname =
          "product-info" === item.id
            ? getSEOScoreLevel({ description: this.props.seo.description })
            : null;

        return {
          ...item,
          active:
            !item.disabled &&
            (null === activeTabIndex ? item.active : activeTabIndex === index),
          content: (
            <LayoutItem
              as={tab.as}
              props={{
                ...tab.props,
                containerClassname,
                placeholder: this.props.placeholder
              }}
              key={index}
            />
          ),
          onClick:
            4 === index
              ? this.review.events.onReadReview
              : e => this.setActiveTab(index)
        };
      }

      return null;
    });

    return (
      <Row>
        <Col sm={12} md={7}>
          <ControlledTabs items={tabs} />
        </Col>
      </Row>
    );
  }

  handleImageChange(selectedIndex) {
    this.fixRibbonStyle(selectedIndex, ribbons => {
      const newState = { activeCarouselIndex: selectedIndex };

      if (ribbons) {
        newState.ribbons = ribbons;
      }

      this.setState(newState);
    });
  }

  renderModalPreview(images) {
    return (
      <ImagePreview
        show={this.state.preview}
        onHide={e => this.setState({ preview: false })}
        onSelect={(element, selectedIndex, direction) => {
          this.setState({ activePreviwIndex: selectedIndex });
        }}
        items={images}
        defaultActiveIndex={this.state.activeCarouselIndex}
      />
    );
  }

  getEnergyClass() {
    const isEnergyClass = item => "attribute_energy_class" === item.type;

    return this.props.specs.items
      .filter(item => item.items.some(isEnergyClass))
      .reduce((carry, item) => {
        const result = item.items.filter(isEnergyClass)[0];

        return result.value[0];
      }, null);
  }

  render() {
    const reducer = ribbon =>
      "ARTICLE_PAGE" === ribbon.itemSelection &&
      ribbon.article_id.includes(+this.props.id);

    const wrapper = (children, props, key) =>
      "corner" === props.type ? (
        <div
          className="position-absolute overflow-hidden"
          key={key}
          style={{
            left: props.imgOffsetLeft,
            height: props.imgHeight,
            width: props.imgWidth
          }}
        >
          {children}
        </div>
      ) : (
        children
      );

    const ribbons = this.renderRibbons(reducer, wrapper);

    // TODO the render complexity should be reduced!
    // this is called by the ImageSlider
    const onImageSliderClick = selectedIndex => {
      // set manually the ImageCarousel's active slide index equal to the ImageSlider's selected index
      this.carouselRef.current.handleSelect(selectedIndex, {
        direction: "next"
      });

      this.handleImageChange(selectedIndex);
    };

    const itemsPerSlide = this.getImageSliderColspan();
    const colSpan = Math.floor(12 / itemsPerSlide);

    // sort images such that the product default image gets first position
    const sortImages = (a, b) => {
      if (a.img.src === this.props.img.src) {
        return -1;
      }
      if (b.img.src === this.props.img.src) {
        return 1;
      }
      return 0;
    };

    //const carouselImgSize = this.getImageCarouselSize();

    const images = this.props.images
      .map(item => ({
        ...item,
        title: item.title,
        img: {
          ...item.img,
          video: SUPPORTED_VIDEO_EXT.includes(item.img.extension),
          removeBackground: false,
          sizes: {
            desktop: 1026,
            mobilePortrait: 270,
            mobileLandscape: 299,
            tabletPortrait: 595,
            tabletLandscape: 893
          }
          // imgSize: {
          //   minHeight: carouselImgSize,
          //   maxHeight: carouselImgSize
          // }
        },
        onClick: e => this.setState({ preview: true })
      }))
      .sort(sortImages)
      .map((item, i) => ({
        ...item,
        img: {
          ...item.img,
          autoPlay: item.img.video && i === this.state.activePreviwIndex
        },
        loading: i && i !== this.state.activeCarouselIndex ? "lazy" : "eager"
      }));

    const sliderImgSize = this.getImageSliderSize();

    const thumbnails = images.map((item, i) => ({
      ...item,
      title: item.title,
      img: {
        ...item.img,
        // enforce image thumbnail
        video: false,
        sizes: { desktop: 110, mobile: 80, tablet: 80 },
        imgSize: {
          minHeight: sliderImgSize,
          maxWidth: 110,
          maxHeight: sliderImgSize,
          objectFit: "contain"
        }
      },
      loading: "lazy"
    }));

    for (let i = 0; i < thumbnails.length % 5; i + 1) {
      thumbnails.push({
        title: "",
        img: {
          as: props => null
        },
        loading: "lazy"
      });
    }

    const helmet = this.props.helmet ? (
      <Helmet prioritizeSeoTags>{toHelmetJSX(this.props.helmet)}</Helmet>
    ) : null;

    const modalPreview = this.renderModalPreview(
      images
      // do not preview videos
      //images.filter(item => item.img && !item.img.video)
    );

    const productExpired = this.props.deleted ? (
      <Row>
        <Col>
          <Alert className="text-center font-weight-bold" variant="danger">
            {this.props.i18n.PRODUCT_EXPIRED}
          </Alert>
        </Col>
      </Row>
    ) : null;

    const topImgSEOClassname = getSEOScoreLevel({
      description: this.props.seo.image
    });

    const buttons = (
      <ProductButtons
        {...this.availability}
        buttons={this.props.buttons}
        wrapper={{ as: Container, props: { className: "p-0 w-100" } }}
      />
    );

    const i18nFavorite = Object.keys(this.props.i18n.favorite).reduce(
      (carry, key) =>
        Object.assign(carry, {
          [key]: this.props.i18n.favorite[key].replace(
            /%product%/g,
            this.props.title
          )
        }),
      {}
    );

    const _imgSVG = imgSVG();

    const imageGalleryBS = "image-gallery";

    const variant = (
      <ProductVariant
        className="w-100 h-100"
        items={this.props.variants}
        title={this.props.variantLabel}
        onVariant={this.props.onVariant}
      />
    );

    const energyClass = this.getEnergyClass();
    const energyClassClick = e => {
      if ("SPAN" === e.currentTarget.tagName) {
        const index = this.props.tabs.items.findIndex(
          item => "product-specification" === item.id
        );

        if (-1 !== index) {
          this.setActiveTabThenScrollIntoView(index);
        }
      } else {
        let index = this.getCarouselIndexByHash(
          this.getCarouselIndexByEnergyLabel(0)
        );

        if (-1 !== index) {
          this.setState({ activeCarouselIndex: index }, () =>
            onImageSliderClick(index)
          );
        }
      }
    };

    const energyLabel = (
      <EnergyClass
        energyClass={energyClass}
        onClick={energyClassClick}
        title={this.props.i18n.PRODUCT_DATASHEET}
      />
    );

    return (
      <React.Fragment>
        <Container
          className={getComponentClassName(
            ProductBS,
            null,
            this.props.className
          )}
          style={this.props.style}
          id={ProductBS.concat("-" + this.props.id)}
        >
          {/* HELMET */}
          {helmet}
          {/* ALERT */}
          {productExpired}

          <Row className="position-md-sticky" style={{ top: "141px" }}>
            <Col sm={12} md={7}>
              <Container className="px-0 px-lg-3">
                <Row>
                  {/* FAVORITE */}
                  {this.props.supportsFavorites ? (
                    <ProductFavorite
                      id={this.props.id}
                      checked={this.props.favorite.isFavorite}
                      onChange={e =>
                        this.props.favorite.onChange(e, e.currentTarget.checked)
                      }
                      i18n={i18nFavorite}
                    />
                  ) : null}

                  {/* TOP IMAGE */}
                  <Col className={topImgSEOClassname}>
                    <ImageCarousel
                      ref={this.carouselRef}
                      items={images}
                      className={joinNonEmptyStrings(
                        ProductBS,
                        "cursor-pointer",
                        " "
                      )}
                      indicators={false}
                      controls
                      magnifier={false}
                      prevIcon={_imgSVG.carouselArrowLeft}
                      nextIcon={_imgSVG.carouselArrowRight}
                      onSelect={(img, selectedIndex, direction) => {
                        if (null !== direction) {
                          this.handleImageChange(selectedIndex);
                        }
                      }}
                      showCaption={false}
                      defaultActiveIndex={this.state.activeCarouselIndex}
                      placeholder={this.props.placeholder}
                      ribbons={ribbons}
                    />
                  </Col>
                </Row>
                {/* IMAGE GALLERY */}
                <Row
                  className={
                    imageGalleryBS + "-wrapper d-flex align-items-center"
                  }
                  style={{ heightX: "270px" }}
                >
                  <Col className="px-0 d-flex eugen">
                    <ImageSlider
                      prefetch
                      preload={false}
                      itemsPerSlide={itemsPerSlide}
                      fitItemsPerSlide={false}
                      colSpan={{ xs: colSpan, sm: colSpan, md: colSpan }}
                      items={thumbnails}
                      className={getComponentClassName(
                        ProductBS,
                        imageGalleryBS,
                        "w-100"
                      )}
                      onClick={onImageSliderClick}
                      alignCenter
                      indicators={false}
                      controls
                      prevIcon={_imgSVG.sliderArrowLeft}
                      nextIcon={_imgSVG.sliderArrowRight}
                      activeIndex={this.state.activeCarouselIndex}
                      inactiveOpacity={0.5}
                      placeholder={this.props.placeholder}
                      squareIcon={true}
                      roundIcon={false}
                      iconSize="3rem"
                      iconPadding="0.5rem"
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col sm={12} md={5}>
              <Container
                className="mt-4 d-md-flex flex-column justify-content-between h-100X"
                style={{ height: "80%" }}
              >
                <Row className="align-content-start flex-row-reverse flex-md-row">
                  {/* TITLE & BRAND */}
                  <Col xs={3} sm={12} className="text-right text-md-left px-0">
                    <span
                      className={getComponentClassName(ProductTitleBS, "brand")}
                      title={this.props.brand.text}
                    >
                      {this.props.brand.title}
                    </span>
                  </Col>
                  <Col
                    xs={9}
                    sm={12}
                    className={
                      getSEOScoreLevel({
                        title: this.props.seo.title
                      }) + " px-0"
                    }
                  >
                    <ProductTitle
                      as="h1"
                      title={this.props.title}
                      subtitleX={this.props.subtitle}
                      className={ProductBS + " my-0"}
                      onClick={this.props.buttons.product.onClick}
                      placeholder={this.props.placeholder}
                    />
                  </Col>
                </Row>
                <div className="abc">
                  <MediaQuery {...mediaBreakpoint.default}>
                    {/* desktop only */}
                    <Row className="align-items-end">
                      {/* VARIANT */}
                      <Col xs={9}>{variant}</Col>
                      {/* ENERGY LABEL */}
                      <Col xs={3} className="text-right">
                        {energyLabel}
                      </Col>
                    </Row>
                    {/* desktop only */}
                    <Row className="align-items-center">
                      {/* BUY BTN */}
                      <Col xs={9}>{buttons}</Col>
                      {/* PRICE */}
                      <Col xs={3}>
                        <ProductPrice
                          i18n={this.props.i18n}
                          newPrice={this.props.newPrice}
                          oldPrice={this.props.oldPrice}
                          currency={this.props.currency}
                          placeholder={this.props.placeholder}
                        />
                      </Col>
                    </Row>
                  </MediaQuery>

                  <MediaQuery {...mediaBreakpoint.mobile}>
                    <Row /* mobile only */>
                      {/* VARIANT */}
                      <Col>{variant}</Col>
                    </Row>

                    <Row /* mobile only */>
                      {/* PRICE & ENERGY LABEL */}
                      <Col sm={12}>
                        <ProductPrice
                          i18n={this.props.i18n}
                          newPrice={this.props.newPrice}
                          oldPrice={this.props.oldPrice}
                          currency={this.props.currency}
                          energyClass={energyClass}
                          energyClassClick={energyClassClick}
                          placeholder={this.props.placeholder}
                        />
                      </Col>
                      {/* BUY BTN */}
                      <Col sm={12}>{buttons}</Col>
                    </Row>
                  </MediaQuery>
                  <Row className="align-content-start mt-3 mt-md-0 stock-status">
                    <Col xs={6} md={12}>
                      {/* STOCK */}
                      {
                        <ProductStockStatus
                          status={this.props.stockStatus}
                          balance={this.props.stockBalance}
                          inStock={this.props.inStock}
                          validFrom={this.props.validFrom}
                          validTo={this.props.validTo}
                        />
                      }
                    </Col>
                    <Col
                      xs={6}
                      md={12}
                      className="text-right text-md-left text-muted font-italic"
                    >
                      {/* LIMITED-OFFER */}
                      Kampanj t.o.m. 31 januari
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col>
                    <Container className="shipping-garanty px-0" fluid>
                      <Row>
                        {/* SHIPPING & WARANTY */}
                        <Col xs={12}>
                          <ul>
                            <li>Leverand inom 2-5 dagar</li>
                            <li>Gratis frakt vid köp över 1000 kr</li>
                            <li>1 års garanti</li>
                          </ul>
                        </Col>
                        {/* VIDEO */}
                        <Col xs={12}>
                          <div className="embed-responsive h-100 embed-responsive-16by9 mt-3">
                            <iframe
                              className="embed-responsive-item"
                              width="560"
                              height="315"
                              src="https://www.youtube.com/embed/cVXr0sT9aSo?si=w3_85jAHbD3rkcud"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <div ref={this.tabbedCardRef} />
          {/* TABBED DESC & SPECS*/}
          {this.getTabbedCard(this.state.activeTabIndex)}
        </Container>
        {modalPreview}
      </React.Fragment>
    );
  }
}

Product.propTypes = {
  ...RibbonAwareComponent.propsTypes,
  ...ProductProps(),
  ...ItemsAwareProps(false, "images", PropTypes.shape(ImageProps)),
  info: PropTypes.shape(TitleTextProps()),
  specs: PropTypes.shape(ItemsAwareProps(false, null, ProductSpecsProps)),
  brand: PropTypes.shape({ ...ImageProps(), text: PropTypes.string }),
  faq: PropTypes.shape(
    ItemsAwareProps(false, null, PropTypes.shape(TitleTextProps()))
  ),
  ...ItemsAwareProps(false, "media", PropTypes.shape(MediaProps())),
  ...ItemsAwareProps(false, "related", PropTypes.shape(ProductProps())),
  ...ItemsAwareProps(false, "variants"),
  variantLabel: PropTypes.string,
  tabs: PropTypes.shape({
    onChange: PropTypes.func,
    ...ItemsAwareProps
  }),
  review: PropTypes.shape(ProductReviewWidgetProps),
  helmet: PropTypes.shape(HelmetProps()),
  favorite: PropTypes.shape({
    isFavorite: PropTypes.bool,
    onChange: PropTypes.func
  }),
  placeholder: PropTypes.bool,
  ribbonWrapsAround: PropTypes.bool,
  onVariant: PropTypes.func
};

Product.defaultProps = {
  ...RibbonAwareComponent.defaultProps,
  allowQtyPicker: false,
  favorite: {},
  ribbonWrapsAround: false
};

export default connectHOCs(Product, { withRedux: true });
